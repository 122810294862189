export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.cookie__inner__title').html('Wir verwenden Cookies');
        $('.cookie__inner__btn--accept').html('Schließen');
        $('.cookie__inner__text p').html('Wir verwenden Cookies nur um Ihre Benutzererfahrung zu verbessern. Diese Cookies sammeln keine persönlichen Daten. Sie können hier mehr lesen über die <a href="/cookies/">Cookies</a> und unseren <a href="/datenschutz/">Datenschutz<a/>.');

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
